import React, {useEffect, useState} from 'react';
import {useParams,} from 'react-router-dom';
import {Avatar, Box, Button, Chip, Grid, LinearProgress, Stack, Typography} from "@mui/material";
import {Token} from "../../models/Token";
import {apiBaseUrl} from "../../Constants";
import {formatTez, getDomainProfileOrWallet} from "../common/utils";
import {PROFILE_PATH} from "../common/Constants";
import TokenTimeline from "./TokenTimeline";


interface TokenPageProps {
    token?: Token;
}

function TokenPage(props: TokenPageProps) {
    const {collectionId, tokenId} = useParams();

    //TODO: could token be read from props? and the component displayed in Outlet?
    let {token} = props;

    const [tokenInfo, setTokenInfo] = useState<Token>();

    useEffect(() => {
        let tokenInfoUrl;
        if (token != null) {
            tokenInfoUrl = `${apiBaseUrl}${token?.collection}/${token?.token}`;
        } else {
            tokenInfoUrl = `${apiBaseUrl}${collectionId}/${tokenId}`;
        }
        fetch(tokenInfoUrl)
            .then(response => response.json())
            .then((response: Token) => setTokenInfo(response))

    }, [collectionId, token, tokenId])


    return (
        <div>
            <Grid container spacing={1}>
                <Grid item sm={12} md={4}>
                    {tokenInfo != null ? <Box
                        component="img"
                        sx={{
                            width: '100%',
                            // maxHeight: { xs: 233, md: 167 },
                            // maxWidth: { xs: 350, md: 250 },
                        }}
                        alt={tokenInfo.title}
                        src={tokenInfo.thumbnail_url}
                    /> : <LinearProgress/>}
                </Grid>
                <Grid item sm={12} md={8}>
                    {/*Token Info*/}
                    {tokenInfo != null && <Box sx={{width: '100%', padding: '1rem'}}>
                        <Chip label={`${formatTez(tokenInfo.value || 0)} TEZ`} variant="outlined"/> Created
                        on: <Chip label={`${new Date(tokenInfo.created).toLocaleDateString()}`} variant="outlined"/>
                        {tokenInfo.artists && tokenInfo.artists.map(author =>
                            <Stack direction={'row'} spacing={2} key={author.wallet} paddingTop={'1rem'}
                                   paddingBottom={'1rem'}>
                                <Avatar src={author.thumbnail_url}/>
                                <Button variant="outlined" style={{color: 'white', marginRight: '1rem'}}>
                                    <a href={`/${PROFILE_PATH}/${author.wallet}`}>
                                        {getDomainProfileOrWallet(author)}
                                    </a>
                                </Button>
                            </Stack>)}
                        <Typography variant="h4" gutterBottom>
                            {tokenInfo.title}
                        </Typography>

                        <Typography>
                            {tokenInfo.description}
                        </Typography>

                    </Box>}

                    {/*  Transaction history  */}
                    <Box sx={{width: '100%', padding: '1rem'}}>
                        <TokenTimeline
                            token={token != null ? token : {collection: collectionId, token: tokenId} as Token}/>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <div style={{height: 10}}/>
                </Grid>
            </Grid>
        </div>

    );
}

export default TokenPage;