import React from 'react';
import {Profile} from "../../models/Profile";
import {Button, Card, CardActions, CardContent, CardMedia} from "@mui/material";
import Typography from "@mui/joy/Typography";
import {formatTez} from "../common/utils";

function ProfileInfo(props: { userId?: string, profile?: Profile }) {
    const {userId, profile} = props;


    return (
        <div>
            {profile && <Card>
                {profile.thumbnail_url && <CardMedia
                    component="img"
                    alt={`${profile.tezos_domain} profile image`}
                    height="140"
                    image={`${profile.thumbnail_url}`}
                />}
                <CardContent>
                    <Typography gutterBottom level="h5" component="div">
                        {profile.tezos_domain || profile.tezos_profile_name || userId}
                    </Typography>
                    <Typography variant="outlined" color="primary">
                        {profile.wallet}
                    </Typography>
                    <Typography>
                        Total NFT worth:
                        <Typography
                            fontSize="xl3"
                            endDecorator={
                                <Typography fontSize="sm" textColor="text.secondary"> TEZ </Typography>
                            }
                            sx={{alignItems: 'center'}}
                        >
                            {formatTez(profile.total_value)}
                        </Typography>
                        <br/>
                        1 / 1 NFTs minted: <strong>{profile.nft_number}</strong>
                        (worth <strong>{formatTez(profile.nft_value)}</strong> Tez)<br/>
                        Multiple editions minted: <strong>{profile.fractional_number}</strong> with total editions
                        of <strong>{profile.fractional_editions_number}</strong> (worth <strong>{formatTez(profile.fractional_value)}</strong> Tez)<br/>
                        Collectors: <strong>{profile.collectors_number}</strong>

                    </Typography>
                </CardContent>
                <CardActions>
                    {profile.twitter_handle != null && <Button size="small"><a
                        href={`https://twitter.com/${profile.twitter_handle}`}>@{profile.twitter_handle}</a></Button>}
                    <Button size="small"><a href={`https://objkt.com/profile/${userId}`}>Objkt.com</a></Button>
                    <Button size="small"><a href={`/profile/${userId}?selectedDay=yesterday`}>daily
                        activity</a></Button>
                </CardActions>
            </Card>}
        </div>

    );
}

export default ProfileInfo;